import FluroContent from ".";

class Reaction extends FluroContent {
    constructor() {
        super("reaction");
    }
}

const _Reaction = new Reaction();
export default _Reaction;
