import Vue from "vue";
import Router from "vue-router";

///////////////////////////////////

//Routes
const Competition = () => import("./competition/index.vue");
const CompetitionDetail = () => import("./competition/_id.vue");

// const Entry = () => import("./entry/index.vue");
const EntryDetail = () => import("./entry/_id.vue");

///////////////////////////////////

//Use the router
Vue.use(Router);

///////////////////////////////////

var array = [];

///////////////////////////////////

array.push({
    name: "competition",
    path: "/competition",
    meta: {
        title: "Competition",
    },
    component: Competition,
});

array.push({
    name: "competition-id",
    path: "/competition/:id",
    meta: {
        title: "Competition Detail",
    },
    component: CompetitionDetail,
});

///////////////////////////////////

// array.push({
//     name: "entry",
//     path: "/entry",
//     meta: {
//         title: "Entry",
//     },
//     component: Entry,
// });

array.push({
    name: "entry-id",
    path: "/entry/:id",
    meta: {
        title: "Entry Detail",
    },
    component: EntryDetail,
});

///////////////////////////////////

var router = new Router({
    mode: "history",
    routes: array,
    scrollBehavior: () => {
        if (Vue.prototype.$app.bus) {
            Vue.prototype.$app.bus.$emit("top");
        }
    },
});

router.beforeEach(async (to, from, next) => {
    if (!to || !to.name || !to.meta) {
        return next("/competition");
    }

    // Adding a move counter for mailing list dialog
    if (Vue.prototype.$mailingList.showDialogAfter > 0) Vue.prototype.$mailingList.showDialogAfter--;

    return next();
});

///////////////////////////////////

export default router;

///////////////////////////////////
