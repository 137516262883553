<template>
    <div ref="scrollContainer" class="app-scrollbar">
        <slot name="default" />
    </div>
</template>

<script>
import SmoothScrollbar from "smooth-scrollbar";

export default {
    name: "app-scrollbar",

    props: {
        options: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },

    data() {
        return {
            scrollbar: null,
        };
    },

    mounted() {
        this.scrollbar = SmoothScrollbar.init(this.$refs.scrollContainer, { ...this.options });
        this.$app.bus.$on("top", () => this.scrollbar.scrollTo(0, 0));
    },

    destroyed() {
        this.scrollbar.destroy();
        this.scrollbar = null;
    },
};
</script>

<style lang="scss">
.app-scrollbar {
    width: 100%;
    height: 100%;

    .scrollbar-track-y {
        right: 3px !important;
        background: 0 0 !important;
        z-index: 200 !important;
    }

    .scrollbar-thumb {
        background: #000000 !important;
        left: initial;
        right: 0;
    }

    @media (max-width: 600px) {
        .scrollbar-thumb {
            width: 3px;
        }
    }
}
</style>
