import Vue from "vue";
import Vuex from "vuex";

////////////////////////////////////////

//Initiate Vuex
Vue.use(Vuex);

////////////////////////////////////////

//Create the store
const store = new Vuex.Store({});

///////////////////////////////////////

//Add to the root
Vue.$store = store;

////////////////////////////////////////

//Export it
export default store;
