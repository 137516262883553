import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        dark: false,
        themes: {
            light: {
                primary: "#5bc0de",
                secondary: "#FFFFFF",

                heading: "#000000",
                grey: "#EEEEEE",
                "dark-grey": "#DDDDDD",
                dark: "#000000",
                background: "#F9F9F9",
                light: "#FFFFFF",

                success: "#46c93a",
                warning: "#ffba00",
                error: "#ff4757",
            },
            dark: {
                primary: "#5bc0de",
                secondary: "#FFFFFF",

                heading: "#FFFFFF",
                grey: "#444444",
                "dark-grey": "#DDDDDD",
                dark: "#FFFFFF",
                background: "#121212",
                light: "#000000",

                success: "#46c93a",
                warning: "#ffba00",
                error: "#ff4757",
            },
        },
    },
});
