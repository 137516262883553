<template>
    <v-form v-model="valid">
        <v-container class="pa-0">
            <v-row dense>
                <v-col cols="12" sm="6">
                    <app-solo v-model="payload.firstName" type="text" :rules="[rules.required]" hide-details :dark="dark" label="First Name"></app-solo>
                </v-col>
                <v-col cols="12" sm="6">
                    <app-solo v-model="payload.lastName" type="text" :rules="[rules.required]" hide-details :dark="dark" label="Last Name"></app-solo>
                </v-col>
                <v-col cols="12">
                    <app-solo v-model="payload.email" type="email" :rules="[rules.required, rules.email]" hide-details :dark="dark" label="Email Address" append-icon="mdi-at"></app-solo>
                </v-col>
                <v-col cols="12">
                    <app-solo v-model="payload.phone" type="text" hide-details :dark="dark" label="Phone Number" append-icon="mdi-phone-outline"></app-solo>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import Reaction from "@/modules/fluro/reaction";

import AppSolo from "@/components/app-solo.vue";

export default {
    name: "app-mailing-list-form",

    props: {
        value: {
            type: Object,
            default() {
                return {
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    mailingList: "",
                };
            },
        },
        dark: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        AppSolo,
    },

    data() {
        return {
            payload: this.value,
            valid: false,
            rules: {
                required: (value) => !!value || "This field is required",
                email: (value) => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid Email.";
                },
            },
        };
    },

    methods: {
        async confirm() {
            try {
                await Reaction.spark("63364062ffdffc2b630474bb", this.payload);
                this.$emit("complete");
            } catch (err) {
                this.$dialog.error(err);
            }
        },
    },

    watch: {
        valid(v) {
            this.$emit("valid", v);
        },
    },
};
</script>

<style lang="scss"></style>
