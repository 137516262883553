<template>
    <v-text-field class="solo" :class="{ flat: !focused }" v-bind="$attrs" v-on="$listeners" @focus="focused = true" @blur="focused = false" ref="solo" solo></v-text-field>
</template>

<script>
export default {
    name: "app-solo",

    data() {
        return {
            el: null,
            focused: false,
        };
    },

    async mounted() {
        this.el = this.$refs.tpInput;
    },
};
</script>

<style lang="scss">
.solo {
    &.flat {
        .v-input__control {
            .v-input__slot {
                box-shadow: none !important;
            }
        }
    }
}
</style>
