import Bus from "vue";
import { API } from "./fluro";

const bus = new Bus();

export default {
    install: function (Vue, { api }) {
        if (!api) throw new Error("API for fluro endpoint required");

        API.use(api);

        const store = new Vue.observable({
            ready: false,
        });

        const mailingList = new Vue.observable({
            showDialogAfter: 4,
        });

        const app = {
            ready: false,
            isReady: () => store.ready,
            initialised: async () => {
                while (!store.ready) {
                    await new Promise((resolve) => setTimeout(resolve, 300));
                }
                return;
            },
            init: async () => {
                Vue.prototype.$app.clearSession();

                await Vue.prototype.$fluro.resetCache();

                Vue.set(store, "ready", true);
            },
            clearSession: () => {
                Vue.set(store, "ready", false);
            },
            bus,
        };

        Vue.prototype.$app = app;
        Vue.prototype.$mailingList = mailingList;
    },
};
