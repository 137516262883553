<template>
    <footer class="app-footer">
        <v-container class="py-7 px-5">
            <v-row>
                <v-col cols="12" sm="6" md="6">
                    <div class="d-flex flex-column align-start">
                        <img alt="logo" class="mb-6" src="@/assets/logo-dark.png" width="100" />
                        <h3 class="white--text mb-6">Australian Photographic Prize Winners</h3>
                        <p class="caption mb-0 white--text">The Australian Photographic Prize is an all-embracing national Photographic Awards event with a 4 day conference and expo that was held in Melbourne. More information coming soon about the next 2023 Australian Photographic Prize Event!</p>
                    </div>
                </v-col>

                <v-col cols="12" sm="6" md="5" offset-md="1">
                    <h3 class="white--text mb-2">Join the mailing list</h3>
                    <p class="caption white--text">Join our mailing list and stay in the loop for our next competition dates!</p>
                    <app-mailing-list-form ref="form" :key="cache" v-model="payload" @valid="setValid" @complete="complete" dark></app-mailing-list-form>

                    <template v-if="joined">
                        <v-btn class="mt-6" dark elevation="0" color="success" block uppercase>Joined</v-btn>
                    </template>

                    <template v-else>
                        <v-btn class="mt-6" dark elevation="0" color="primary" :disabled="!valid" :loading="loading" block @click="confirm()" uppercase>Join</v-btn>
                    </template>
                </v-col>
            </v-row>
        </v-container>

        <v-container class="py-7 px-4">
            <v-row>
                <v-col cols="12">
                    <p class="text-center ma-0 caption white--text">Australian Photographic Prize 2022</p>
                </v-col>
            </v-row>
        </v-container>
    </footer>
</template>

<script>
import AppMailingListForm from "@/components/app-mailing-list-form.vue";

export default {
    name: "app-footer",

    components: {
        AppMailingListForm,
    },

    data() {
        return {
            payload: {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                mailingList: "63364274ffdffc2b630474cb",
            },
            loading: false,
            joined: false,
            valid: false,
            cache: 0,
        };
    },

    methods: {
        cancel() {
            localStorage.setItem("seen_mailing_dialog", true);
        },
        async confirm() {
            this.loading = true;

            await this.$refs.form.confirm();

            this.loading = false;
        },
        complete() {
            this.joined = true;
            this.reset();
        },
        setValid(v) {
            this.valid = v;
        },
        reset() {
            this.payload = {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                mailingList: "63364274ffdffc2b630474cb",
            };

            this.cache++;
        },
    },
};
</script>

<style lang="scss" scoped>
.app-footer {
    background-color: #121212;
}
</style>
