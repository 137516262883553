import Vue from "vue";
import App from "./app.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

/////////////////////////////////////////////////////

import "@/styles/helpers.scss";

/////////////////////////////////////////////////////

import Meta from "vue-meta";

Vue.use(Meta, {
    keyName: "meta",
    attribute: "data-vue-meta",
    ssrAttribute: "data-vue-meta-server-rendered",
    tagIDKeyName: "vmid",
    refreshOnceOnNavigation: true,
});

/////////////////////////////////////////////////////

import FluroVue from "fluro-vue";
Vue.use(FluroVue, { store });

var fluro = Vue.prototype.$fluro;

/////////////////////////////////////////////////////

// App Module
import AppModule from "@/modules";
Vue.use(AppModule, { api: fluro.api });

/////////////////////////////////////////////////////

Vue.prototype.$app.init();

/////////////////////////////////////////////////////

// EVENT BUS
export const EventBus = new Vue();

Vue.config.productionTip = false;

new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
