<template>
    <v-app id="app">
        <template v-if="$app.isReady()">
            <app-scrollbar>
                <v-main>
                    <router-view />
                    <app-footer></app-footer>
                </v-main>
            </app-scrollbar>
        </template>

        <template v-else>
            <v-overlay color="light" opacity="0.85" class="p-absolute">
                <v-container fluid pa-0 d-flex flex-column align-center>
                    <img alt="logo" class="mx-auto d-block mb-6" src="@/assets/logo-light.png" width="150" />
                    <v-progress-circular :size="24" :width="2" color="primary" indeterminate></v-progress-circular>
                </v-container>
            </v-overlay>
        </template>
    </v-app>
</template>

<script>
import AppScrollbar from "@/components/app-scrollbar.vue";
import AppFooter from "@/components/app-footer.vue";

export default {
    name: "app",

    meta: {
        titleTemplate: "%s - Australian Photographic Prize Winners",
    },

    components: {
        AppScrollbar,
        AppFooter,
    },
};
</script>

<style lang="scss">
* {
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
    font-family: "Poppins", sans-serif;

    ::-webkit-scrollbar {
        display: none;
    }
}

html,
body,
#app,
.v-application--wrap {
    min-height: -webkit-fill-available !important;
    height: -webkit-fill-available !important;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: column;
    overflow: hidden;
}
</style>
